import {hot} from "react-hot-loader"
import {useEffect} from "react"
import {Provider, useDispatch} from "react-redux"
import {Loader} from "rsuite"
import store from "../store/store"
import {useAccountingBaseDataQuery} from "./store/accounting-api"
import {setContext} from "./store/entries-slice"
import RSuiteLocale from "../i18n/rsuite-locale"


const AccountingProvider = ({children, ...props}) => {
    return (
        <RSuiteLocale>
            <Provider store={store}>
                <Initializer {...props}>
                    {children}
                </Initializer>
            </Provider>
        </RSuiteLocale>
    )
}
export default hot(module)(AccountingProvider)

function Initializer({children, context, additional}) {
    const dispatch = useDispatch()
    const {isLoading} = useAccountingBaseDataQuery()

    useEffect(
        () => {
            dispatch(setContext(context, additional))
        },
        [context, JSON.stringify(additional)]
    )

    return isLoading ? <Loader/> : <>{children}</>
}
